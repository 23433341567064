// import styled from 'styled-components'

// import device from 'utils/device'

// const Band = () => {
//     return <Container>Black Friday sale on the longevity app that can change your life. Get your special 72% discount now!</Container>
// }

// const Container = styled.div`
//     font-weight: 600;
//     font-size: 0.22rem;
//     background: var(--color-dark);
//     text-align: center;
//     padding: 0.14rem 0 0.12rem;
//     margin-bottom: 0.48rem;
//     justify-content: center;
//     display: flex;
//     color: #fff;
//     ${device.mobile} {
//         font-size: 0.18rem;
//         display: block;
//         margin-bottom: 0.24rem;
//         padding: 0.1rem 0 0.08rem;
//     }
// `

// export default Band;


import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import styled from 'styled-components'

import device from 'utils/device'

const CountdownTimer = ({discount = 1, text, showTimer = false, m = false, t = false}) => {
    const cookies = new Cookies();
    const duration = 15 * 60; // 15 minutes in seconds
    const now = Math.floor(Date.now() / 1000); // current time in seconds

    // Get the start time from cookie or set it if not available
    const startTime = cookies.get('startTime') || now;

    if (!cookies.get('startTime') && showTimer) {
        cookies.set('startTime', now, { path: '/', maxAge: duration }); // Set the cookie for 15 minutes
    }

    const [timeLeft, setTimeLeft] = useState(duration - (now - startTime));

    useEffect(() => {
        if (!showTimer) return;
        
        if (timeLeft > 0) {
            const interval = setInterval(() => {
                setTimeLeft(prevTime => prevTime - 1);
            }, 1000);

            return () => clearInterval(interval); // Cleanup on component unmount
        } else {
            cookies.remove('startTime', { path: '/' }); // Remove the cookie once timer ends
        }
    }, [timeLeft]);

    const formatTime = () => {
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const content = text ? text : `Get ${discount}% discount!`;

    return <>
        <Container m={m} o={!!t}><Left>{content}</Left> {showTimer && <><Small>Offer ends in:</Small> 00:{formatTime()}</>}</Container>
        {t && <Container t={true}><Left>{content}</Left> {showTimer && <><Small>Offer ends in:</Small> 00:{formatTime()}</>}</Container>}
    </>
}

const Container = styled.div`
    font-weight: 600;
    font-size: 0.22rem;
    background: var(--color-dark);
    text-align: center;
    padding: 0.14rem 0 0.12rem;
    margin-top: ${props=>props.m ? '-0.24' : '0'}rem;
    margin-bottom: ${props=>props.m ? '0.24' : '0.48'}rem;
    justify-content: center;
    display: flex;
    color: #fff;
    width: 100%;
    z-index: 3;
    top: 0;
    opacity: ${props => props.o ? 0 : 1};
    position:${props => props.t ? 'fixed' : 'relative'};
    ${device.mobile} {
        font-size: 0.16rem;
        display: block;
        margin-bottom: 0.24rem;
        padding: 0.1rem 0 0.08rem;
        
        width: auto;
        min-width: 100%;
        margin-top: ${props=>props.m ? '-0.12' : '0'}rem;
        margin-left: ${props=>props.m ? '-0.12' : '0'}rem;
        margin-right: ${props=>props.m ? '-0.12' : '0'}rem;
        margin-bottom: ${props=>props.m ? '0.12' : '0.24'}rem;
    }
`
// margin-top: ${props=>props.m ? '-0.24' : '0'}rem;

const Left = styled.div`
    margin-right: 0.3rem;
    &:last-child {
        margin-right: 0;
    }
    ${device.mobile} {
        margin: 0;
    }
`

const Small = styled.span`
    font-size: 0.14rem;
    margin-right: 0.04rem;
    position: relative;
    top: 0.55em;
    font-weight: 400;
    ${device.mobile} {
        top: -0.05em;
    }
`

export default CountdownTimer;
